.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}

.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  justify-content: space-between;
}

.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer-text {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}

.footer-container2 {
  display: flex;
  justify-content: space-between;
}

.footer-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer-title {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}

.footer-link-name {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}

.footer-link-name:hover {
  color: var(--dl-color-gray-white);
}

.footer-text05 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}

.footer-text05:hover {
  color: var(--dl-color-gray-white);
}

.footer-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}

.footer-container5 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}

.footer-image {
  top: 0;
  left: auto;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}

@media(width <= 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
}

@media(width <= 767px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-container1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  .footer-container4 {
    margin-left: var(--dl-space-space-doubleunit);
  }

  .footer-container5 {
    margin-left: var(--dl-space-space-doubleunit);
  }
}
