.primary-pink-button-container {
  display: flex;
  position: relative;
  min-width: 120px;
}

.primary-pink-button-button {
  color: var(--dl-color-gray-white);
  outline: none;
  background: linear-gradient( 310deg ,#7928ca,#ff0080);
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  transition: all .15s ease-in;
  padding: var(--dl-space-space-triplequarterunit) var(--dl-space-space-unitandahalfunit) var(--dl-space-space-triplequarterunit) var(--dl-space-space-unitandahalfunit);
  border-width: 0;
  border-radius: 1.875rem;
  width: 100%;
}

.primary-pink-button-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
