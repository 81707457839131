.header-header {
  inset: 0 auto auto;
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.header-nav {
  flex: 0 0 auto;
  color: var(--dl-color-secondary-600);
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row wrap;
  box-shadow: 0 .25rem .375rem -.0625rem hsl(0deg 0% 8% / 12%),0 .125rem .25rem -.0625rem hsl(0deg 0% 8% / 7%)!important;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding: var(--dl-space-space-halfunit) var(--dl-space-space-unitandahalfunit) var(--dl-space-space-halfunit) var(--dl-space-space-unitandahalfunit);
  border-radius: var(--dl-radius-radius-radius40);
  backdrop-filter: saturate(200%) blur(30px);
  justify-content: space-between;
  background-color: hsl(0deg 0% 100% / 80%)!important;
}

.header-container {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-navlink {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.header-navlink1 {
  margin: var(--dl-space-space-halfunit) var(--dl-space-space-unit) var(--dl-space-space-halfunit) var(--dl-space-space-unit);
  transition: 0.3s;
  text-decoration: none;
}

.header-navlink1:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink2 {
  margin: var(--dl-space-space-halfunit) var(--dl-space-space-unit) var(--dl-space-space-halfunit) var(--dl-space-space-unit);
  transition: 0.3s;
  text-decoration: none;
}

.header-navlink2:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink3 {
  margin: var(--dl-space-space-halfunit) var(--dl-space-space-unit) var(--dl-space-space-halfunit) var(--dl-space-space-unit);
  transition: 0.3s;
  text-decoration: none;
}

.header-navlink3:hover {
  color: var(--dl-color-gray-500);
}

.header-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-icon {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}

.header-mobile-menu {
  top: 0;
  flex: 0 0 auto;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-unitandahalfunit);
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.header-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-navlink4 {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  text-decoration: none;
}

.header-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.header-icon2 {
  width: 24px;
  height: 24px;
}

.header-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.header-menu1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.header-navlink5 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink5:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink6 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink6:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink7 {
  transition: 0.3s;
  text-decoration: none;
}

.header-navlink7:hover {
  color: var(--dl-color-gray-500);
}

.header-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  flex-direction: column;
}

@media(width <= 991px) {
  .header-nav {
    max-width: 960px;
  }

  .header-menu {
    display: none;
  }
}

@media(width <= 767px) {
  .header-burger-menu {
    display: flex;
  }
}

@media(width <= 479px) {
  .header-container2 {
    display: none;
  }
}
